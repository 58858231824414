

































































































































































import Vue from "vue";
import { Messages } from "@/models/enums/messages.enum";
import { settingsServices } from "@/services/settings.service";
import {
  DataCurrency,
  DataListCurrency,
} from "@/models/interface/settings.interface";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { ResponsePagination } from "@/models/constant/interface/common.interface";
import moment from "moment";
import { debounce, debounceProcess } from "@/helpers/debounce";
import {
  changeCurrencytoNumeric,
  currencyFormat,
} from "@/validator/globalvalidator";
export default Vue.extend({
  data() {
    this.handleSearchSelectTable = debounceProcess(
      this.handleSearchSelectTable,
      400
    );
    return {
      modalOpen: false as boolean,
      dataListCurrencyCreate: [],
      formatCurrencytoNumber: changeCurrencytoNumeric,
      totalData: 0 as number,
      direction: "" as string,
      typemodal: "create" as string,
      form: this.$form.createForm(this, { name: "currency" }),
      isFormSubmitted: false as boolean,
      page: this.page || (1 as number),
      limit: this.limit || (10 as number),
      dataListCurrency: [] as DataCurrency[],
      search: "" as string,
      sort: "" as string,
      authPrivilege: [] as string[],
      listDeletedCurrency: [] as any[],
      labelCol: { span: 6 },
      selectedRowKeys: [] as number[],
      wrapperCol: { span: 16 },
      titlemodalOpen: "Currency" as string,
      trigerdisable: false as boolean,
      loadingTable: false as boolean,
      initListCurrency: [] as any[],
      formRules: {
        code: {
          label: "lbl_currency_code",
          name: "Currency Code",
          placeholder: "lbl_currency_code_placeholder",
          decorator: [
            "code",
            {
              rules: [
                {
                  required: true,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        description: {
          label: "lbl_description",
          name: "Description",
          placeholder: "lbl_description_placeholder",
          decorator: [
            "description",
            {
              rules: [
                {
                  required: true,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        fromcurrency: {
          label: "lbl_from_currency",
          name: "From Currency",
          placeholder: "lbl_from_currency_placeholder",
          decorator: [
            "fromcurrency",
            {
              rules: [
                {
                  required: true,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        tocurrency: {
          label: "lbl_to_currency",
          name: "To Currency",
          placeholder: "lbl_to_currency_placeholder",
          decorator: [
            "tocurrency",
            {
              rules: [
                {
                  required: true,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        rate: {
          label: "lbl_rate",
          name: "Rate",
          placeholder: "lbl_rate_placeholder",
          decorator: [
            "rate",
            {
              rules: [
                {
                  required: true,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
      },
      columnsTable: [
        {
          title: this.$t("lbl_from_currency"),
          dataIndex: "fromCurrencyId",
          key: "currencyfrom",
          width: 100,
          scopedSlots: { customRender: "fromCurrencyId" },
          responsiveColSelect: [
            {
              name: "fromCurrencyId",
              placeholder: this.$t("lbl_type_to_find_placeholder"),
              style: "width: 100%;",
              value: "id",
              options: [] as DataListCurrency[],
            },
            {
              name: "toCurrencyId",
              placeholder: this.$t("lbl_type_to_find_placeholder"),
              style: "width: 100%;",
              disabled: "",
              value: "id",
              options: [] as DataListCurrency[],
            },
          ],
          responsiveColInput: [
            {
              name: "rate",
              placeholder: this.$t("lbl_rate"),
              style: "width: 100%;",
              disabled: "",
            },
          ],
          responsiveColRangePicker: [
            {
              name: "rangePicker",
              placeholder: this.$t("lbl_date"),
              style: "width: 100%;",
              disabled: "",
            },
          ],
        },
        {
          title: this.$t("lbl_to_currency"),
          dataIndex: "toCurrencyId",
          key: "currencyto",
          width: 100,
          scopedSlots: { customRender: "toCurrencyId" },
        },
        {
          title: this.$t("lbl_rate"),
          dataIndex: "rate",
          key: "rate",
          sorter: true,
          width: 100,
          scopedSlots: { customRender: "rate" },
        },
        {
          title: this.$t("lbl_date"),
          dataIndex: "rangePicker",
          key: "date",
          sorter: true,
          width: 150,
          scopedSlots: { customRender: "rangePicker" },
        },
      ] as any,
      columnsTableList: [
        {
          title: this.$t("lbl_currency_code"),
          dataIndex: "currencyCode",
          key: "currencyCode",
          width: 100,
          scopedSlots: { customRender: "isNull" },
        },
        {
          title: this.$t("lbl_description"),
          dataIndex: "description",
          key: "description",
          width: 100,
          scopedSlots: { customRender: "isNull" },
        },
      ] as any,
    };
  },
  async mounted() {
    await this.getListOfMasterCurrency("");
    this.getListOfCurrency("");
  },
  methods: {
    moment,
    handleSearchSelectTable(value, record, name) {
      let params = {} as RequestQueryParamsModel;
      if (value)
        params.search = `currencyCode~*${value}*_OR_description~*${value}`;
      if (!value) params.page = 0;
      settingsServices.listOfMasterCurrency(params, "").then((data: any) => {
        data.data.map(
          (dataObject, index) => (
            (dataObject.key = index),
            (dataObject.name = dataObject.currencyCode)
          )
        );
        this.dataListCurrency[record].optionCustom.find(
          item => item.name === name
        ).option = data.data;
      });
    },
    handleDatePicker(date, key, _dateString) {
      this.dataListCurrency[key]["fromDate"] = date[0]._d;
      this.dataListCurrency[key]["toDate"] = date[1]._d;
    },
    showConfirmation() {
      if (this.selectedRowKeys.length > 0) {
        this.$confirm({
          title: `Do you want to delete these items?`,
          content: `Total: ${this.selectedRowKeys.length} items will be deleted.`,
          onOk: () => {
            this.handleDeleteRow();
          },
          onCancel() {
            return;
          },
        });
      } else {
        this.$notification.error({
          message: "Error",
          description: "Select at least one row to delete",
        });
      }
    },
    onSorterChange(response): void {
      if (response.sort.order) {
        this.direction = response.sort.order === "ascend" ? "asc" : "desc";
        this.sort = `${response.sort.field}:${this.direction}`;
      } else {
        this.ResetFilter(false);
      }
      this.getListOfMasterCurrency("");
    },
    handleSelectTable(value, key, col, _recordOptions): void {
      this.dataListCurrency[key] = {
        ...this.dataListCurrency[key],
        [col]: value,
      };
      this.dataListCurrency = this.dataListCurrency.slice();
    },
    saveRateCurrency(): void {
      const datasave = [] as any;
      this.dataListCurrency.forEach(item => {
        let dataObj = {
          fromCurrencyId: item.fromCurrencyId ? item.fromCurrencyId : null,
          fromDate: item.fromDate ? this.moment(item.fromDate).format() : null,
          rate: item.rate ? this.formatCurrencytoNumber(item.rate + "") : null,
          toCurrencyId: item.toCurrencyId ? item.toCurrencyId : null,
          toDate: item.toDate ? this.moment(item.toDate).format() : null,
        };
        if (item.id) dataObj["conversionSecureId"] = item.id;
        datasave.push(dataObj);
      });
      const datapushCurrency = {
        deletedIds: this.listDeletedCurrency,
        requestDTO: datasave,
      };
      settingsServices.createCurrency(datapushCurrency).then(() => {
        this.listDeletedCurrency = [];
        this.getListOfCurrency("");
        this.$notification.success({
          description: Messages.CREATE_SUCCESS,
          message: "Success",
          duration: 30,
        });
      });
    },
    handleInput(
      value,
      key,
      objectColInput,
      _objectColInputName: string,
      onEvt: string
    ) {
      let formatedVal: string | number = value;
      if (onEvt === "onBlur") {
        formatedVal = currencyFormat(value);
      } else if (onEvt === "onFocus") {
        formatedVal = this.formatCurrencytoNumber(value);
      }
      this.dataListCurrency[key][objectColInput.name] = formatedVal;
      this.dataListCurrency = this.dataListCurrency.slice();
    },
    handleInputNumber(value, key, objectColInputNumber) {
      this.dataListCurrency[key][objectColInputNumber.name] = value;
      this.dataListCurrency = this.dataListCurrency.slice();
    },
    handleDate(_value, key, objectColDate, dateString) {
      this.dataListCurrency[key][objectColDate.name] = dateString;
      this.dataListCurrency = this.dataListCurrency.slice();
    },
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys;
    },
    createNew() {
      this.modalOpen = true;
    },
    handleDeleteRow() {
      this.dataListCurrency = this.dataListCurrency.filter(data => {
        if (this.selectedRowKeys.includes(data.key)) {
          this.listDeletedCurrency.push(data.id);
        }
        return !this.selectedRowKeys.includes(data.key);
      });
      this.dataListCurrency.forEach((data, index) => (data.key = index));
      this.dataListCurrency = this.dataListCurrency.slice();
      this.totalData = this.dataListCurrency.length;
      this.selectedRowKeys = [];
    },
    handleAddRow(): void {
      this.dataListCurrency = [
        ...this.dataListCurrency,
        {
          fromCurrencyId: null,
          id: null,
          key: this.dataListCurrency.length,
          rate: null,
          toCurrencyId: null,
          toDate: null,
          fromDate: null,
          optionCustom: [
            {
              name: "fromCurrencyId",
              option: this.initListCurrency,
            },
            {
              name: "toCurrencyId",
              option: this.initListCurrency,
            },
          ],
        },
      ];
      this.totalData = this.dataListCurrency.length;
    },
    handleCancel() {
      this.modalOpen = false;
    },
    ResetFilter(getlist: boolean): void {
      this.direction = "";
      this.search = "";
      if (getlist) this.getListOfCurrency("");
    },
    responsePageSizeChange(response: ResponsePagination): void {
      this.limit = response.size;
      this.page = 1;
      this.getListOfCurrency("");
    },
    responseCurrentPageChange(response: ResponsePagination): void {
      this.page = response.page;
      this.getListOfCurrency("");
    },
    getListOfCurrency(path: string) {
      let params: any;
      if (!path) {
        params = {
          limit: this.limit,
          page: this.page - 1,
          sorts: "createdDate:desc",
        } as RequestQueryParamsModel;
        if (this.search) params.search = this.search;
        if (this.direction) params.sorts = this.sort;
      }
      this.loadingTable = true;
      return settingsServices
        .listOfCurrency(params, "")
        .then((res: any) => {
          if (!path) {
            res.data.map(
              (dataObject, index) => (
                (dataObject.rate = currencyFormat(dataObject.rate)),
                (dataObject.key = index),
                (dataObject.rangePicker = dataObject.fromDate
                  ? [
                      this.moment(dataObject.fromDate).format("YYYY-MM-DD"),
                      this.moment(dataObject.toDate).format("YYYY-MM-DD"),
                    ]
                  : "")
              )
            );
            this.dataListCurrency = res.data;
            this.dataListCurrency.forEach(item => {
              item.optionCustom = [
                {
                  name: "fromCurrencyId",
                  option: this.initListCurrency,
                },
                {
                  name: "toCurrencyId",
                  option: this.initListCurrency,
                },
              ];
            });
            this.totalData = res.totalElements;
          } else {
            debounce(() => {
              this.form.setFieldsValue({
                fromCurrencyId: res.fromCurrencyId,
                rate: res.rate,
                toCurrencyId: res.toCurrencyId,
              });
            }, 200);
          }
        })
        .finally(() => (this.loadingTable = false));
    },
    getListOfMasterCurrency(path: string) {
      let params: any;
      if (!path) {
        params = {
          limit: 1000,
          page: 0,
        } as RequestQueryParamsModel;
        if (this.search) params.search = this.search;
        if (this.direction) params.sorts = this.sort;
      }
      this.initListCurrency = [];
      return settingsServices
        .listOfMasterCurrency(params, path)
        .then((res: any) => {
          res.data.map(
            (dataObject, index) => (
              (dataObject.key = index),
              (dataObject.name = dataObject.currencyCode)
            )
          );
          this.initListCurrency = res.data;
          this.dataListCurrencyCreate = res.data;
        });
    },
    submitForm(): void {
      this.form.validateFields((err, values) => {
        if (!err) {
          const datapost = {
            currencyCode: values.code,
            description: values.description,
          };
          this.isFormSubmitted = true;
          settingsServices
            .createMasterCurrency(datapost)
            .then(res => {
              if (res) {
                this.getListOfCurrency("");
                this.getListOfMasterCurrency("");
                this.form.resetFields();
                this.$notification.success({
                  description: Messages.CREATE_SUCCESS,
                  message: "Success",
                  duration: 30,
                });
                this.modalOpen = false;
              }
            })
            .catch(e => {
              this.isFormSubmitted = false;
              this.$notification.error({
                description: e.details,
                message: "Error",
                duration: 30,
              });

              this.$notification.error({
                description: Messages.CREATE_FAIL,
                message: "Error",
                duration: 30,
              });
            })

            .finally(() => (this.isFormSubmitted = false));
        } else {
          this.$notification["error"]({
            message: "Error",
            description: "Form is mandatory",
          });
        }
      });
    },
  },
});
